/*global History, Modernizr, FastClick, alert*/
/*jshint sub:true*/

window.main = window.main || {};

//General

var siteName = 'Willy Vanderperre';
var pageTitle;

var $htmlbody = $('html, body');
var $body = $('body');

var ww = $(window).width();
var wh = $(window).height();
var dw = $(document).width();
var dh = $(document).height();
var elem = document.documentElement;

var animationSpeed = 440;

var brxlarge = 1800;
var brlarge = 1440;
var brmedlarge = 1280;
var brmedium = 1024;
var brmedsmall = 768;
var brsmall = 515;
var brxsmall = 340;

var slider;

//Components

var $header = $('.js-header');
var $main = $('.js-main');
var $nav = $('.js-nav');

var $btnToggleSound = $('.js-toggle-sound');
var btnToggleSound = '.js-toggle-sound';
var $btnToggleNav = $('.js-toggle-nav');
var btnToggleNav = '.js-toggle-nav';

var $btnToggleFullscreen = $('.js-toggle-fullscreen');
var btnToggleFullScreen = '.js-toggle-fullscreen';
var $btnTogglePage = $('.js-toggle-page');
var btnTogglePage = '.js-toggle-page';
var $sliderCellLink = $('.js-slider__cell--link');
var sliderCellLink = '.js-slider__cell--link';
var $thumbnail = $('.js-thumbnail');

var $moveBanner = $('.js-banner--move');
var $movingTitle = $('.js-title-move');
var movingTitleWidth = 0;

// Checks

var isFullScreen = false;
var isHomepage = false;
var isTouchdevice = false;
var isPageOpen = false;
var isNavOpen = false;
var isZoomViewOpen = false;

main.init = function() {

  //objectFitImages();
  main.initEvents();
  main.setLayout();

  if($body.hasClass('allow-screensaver')) {
    main.initInactivityMonitor();
  }

  if (sessionStorage.getItem('splash') !== 'true') {
    if ( $('.js-splash').length ) {
      main.initSplash('.js-splash');
    } else {
      setTimeout(function() {
          main.showMain();
      },220);
    }
    sessionStorage.setItem('splash','true');
  } else {
    if ( $('.js-splash').length ) {
      main.removeSplash();
    } else {
      setTimeout(function() {
          main.showMain();
      },220);
    }
  }

  if ( $('select[name="country"]').length ) {
		main.cartListenToChange('select[name="country"]');
	}

  if ( $('input[name="shipping"]').length ) {
		main.cartListenToChange('input[name="shipping"]');
	}

  if ( $('.js-slider').length ) {
		main.initSlider('.js-slider');
	}

  if( $('.js-video').length ) {
    main.initVideo();
  }

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isTouchdevice = true;
    $body.addClass('is-touch');
    $body.addClass('is-mobile-device');
  }

  document.addEventListener('touchmove', function (event) {
    if (event.scale !== 1) { event.preventDefault(); }
  }, false);

  var lastTouchEnd = 0;
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);

  $(window).on('load', function() {
    main.setLayout();
    if ( $('.js-form').length ) {
      main.initForm();
    }
  });

  function onWindowResize() {
    if (!$main.hasClass('is-active')) {
      $main.addClass('is-active');
    }
  }

  var resized;
  $(window).on('resize', function() {
    if(isTouchdevice == false) {
      $main.removeClass('is-active');
      clearTimeout(resized);
    	resized = setTimeout(onWindowResize, 1000);
    }
    main.setLayout();
  });

};

// main.onWindowResize = function() {
//   if (!$main.hasClass('is-active')) {
//     $main.addClass('is-active');
//   }
// 	// if($main.hasClass('is-active')) {
//   //   $main.removeClass('is-active');
//   // }
// };

main.showMain = function() {
  $main.addClass('is-active');
};

main.hideMain = function() {
  $main.removeClass('is-active');
};

main.initSplash = function() {

  setTimeout(function() {
    $('.js-splash').addClass('has-text-visible');

    $moveBanner.on('mousemove', function(e) {
      e.preventDefault();
      var titleToMove = $(this).find('.js-title-move');
      main.moveTitle(e, titleToMove);
    });

    $moveBanner.on('touchmove', function(e) {
      e.preventDefault();
      var titleToMove = $(this).find('.js-title-move');
      main.touchTitle(e, titleToMove);
    });

  },220);

  setTimeout(function(){main.removeSplash();},20000);


};

main.removeSplash = function() {
  $('.js-splash').removeClass('is-active');
  setTimeout(function() {
    main.showMain();
  },220);
};

main.initInactivityMonitor = function() {

    var screensaverInterval = null;
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer; // catches touchscreen presses
    window.onclick = resetTimer; // catches touchpad clicks
    window.onscroll = resetTimer; // catches scrolling with arrow keys
    window.onkeypress = resetTimer;

    function startScreensaver() {
      if ( ww > brmedsmall ) {
        if(isNavOpen == false) {
          $body.addClass('inactivity-view');
        }
      }
    }

    function resetTimer() {
      setTimeout(function() {
        $body.removeClass('inactivity-view');
      },animationSpeed/2 );
      clearTimeout(t);
      t = setTimeout(startScreensaver, 4000); // time is in milliseconds
    }

};

main.cartListenToChange = function(selector) {
  var $elements = $(selector);
  $elements.change(main.cartChangeHandler);
};

main.cartChangeHandler = function(e) {
  this.closest("form").submit();
};

main.initForm = function() {

  //Loop through all form fields on page load
  $('.js-form__group .o-form__field').each(function(){
    if( $(this).length && $(this).val().length ) {
      $(this).parent().addClass('has-label-active');
    } else {
      $(this).parent().removeClass('has-label-active');
    }
  });

  //Add listener event to keep form fields up to date
  $('.js-form__group .o-form__field').keyup(function(){
    if( $(this).length && $(this).val().length ) {
      $(this).parent().addClass('has-label-active');
    } else {
      $(this).parent().removeClass('has-label-active');
    }
	});

};

main.initEvents = function() {

  $btnToggleFullscreen.on('click', function(e) {
    e.preventDefault();
    main.toggleFullScreen();
  });

  $btnToggleNav.on('click', function(e) {
    e.preventDefault();
    main.toggleNav();
  });

  $btnToggleSound.on('click', function(e) {
    e.preventDefault();
    main.toggleSound();
  });

  $btnTogglePage.on('click', function(e) {
    e.preventDefault();
    main.togglePage($(this).attr('href'));
  });

  /*
  $sliderCellLink.on('click', function(e) {
    e.preventDefault();
    window.location.href = $(this).data('href');
    //main.togglePage($(this).attr('href'));
  });
  */

  $('.js-splash').on('click', function() {
    main.removeSplash();
  });

  $('body').on('click', function(e) {
    if(isZoomViewOpen == true) {
      main.closeZoomView();
    }
  });

};

main.moveTitle = function(e, self) {

  var x = e.clientX;
  var y = e.clientY;

  var xPC = (e.clientX / ww) * 100;
  var xPCtitle;
  var xPCtitleReverse;

  movingTitleWidth = self.outerWidth() - ww;
  xPCtitle = xPC * (movingTitleWidth / 100);
  xPCtitleReverse = xPCtitle - movingTitleWidth;

  if (self.outerWidth() > ww) {
    self.css({
      'transform': 'translateX(' + -xPCtitle + 'px)'
    });
  } else {
    self.css({
      'transform': 'translateX(' + xPCtitleReverse + 'px)'
    });
  }

};

main.touchTitle = function(e, self) {

  var x = e.touches[0].clientX;
  var y = e.touches[0].clientY;

  var xPC = (e.touches[0].clientX / ww) * 100;
  var xPCtitle;
  var xPCtitleReverse;

  movingTitleWidth = self.outerWidth() - ww;
  xPCtitle = xPC * (movingTitleWidth / 100);
  xPCtitleReverse = xPCtitle - movingTitleWidth;

  if (self.outerWidth() > ww) {
    self.css({
      'transform': 'translateX(' + -xPCtitle + 'px)'
    });
  } else {
    self.css({
      'transform': 'translateX(' + xPCtitleReverse + 'px)'
    });
  }

};

main.setTitlePositions = function() {

  $movingTitle.each(function() {

    $(this).css({
      'left': '0'
    });

    var movingTitleMargins;

    movingTitleMargins = ww - $(this).outerWidth();
    movingTitleTranslate = movingTitleMargins / 2;

    $(this).css({
      'transform': 'translateX(' + movingTitleTranslate + 'px)'
    });

  });

};


main.openZoomView = function() {

    $body.addClass('focus-view');
    $body.addClass('disable-clicks');
    setTimeout(function() {
      $body.addClass('zoom-view');
    },animationSpeed+animationSpeed/2);
    setTimeout(function() {
      //Only enable 'isZoomViewOpen' at the end to prevent closing by click too early
      isZoomViewOpen = true;
    },animationSpeed+animationSpeed/2+animationSpeed);

};

main.closeZoomView = function() {

    isZoomViewOpen = false;
    $body.removeClass('zoom-view');
    setTimeout(function() {
      $body.removeClass('focus-view');
    },animationSpeed+animationSpeed/2);
    setTimeout(function() {
      //Only remove the class that disables clicks at the end to prevent opening the view too soon after closing
      $body.removeClass('disable-clicks');
    },animationSpeed+animationSpeed/2+animationSpeed);


};

main.toggleFullScreen = function() {

  isFullScreen = !isFullScreen;

  if(isFullScreen == true) {
    main.openFullscreen();
  } else {
    main.closeFullscreen();
  }
};

main.openFullscreen = function() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

main.closeFullscreen = function() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

main.toggleNav = function(page) {

  isNavOpen = !isNavOpen;

  if(isNavOpen == true) {
      $body.addClass('overlay-nav-is-open');
      $nav.addClass('is-active');
  } else {
      $body.removeClass('overlay-nav-is-open');
      $nav.removeClass('is-active');
  }

  /*
  setTimeout(function(){
    $('.js-overlay-page[data-page="'+page+'"]').toggleClass('is-active');
    $('nav ul li a[rel="'+page+'"]').parent().toggleClass('is-active');
  },440);
  */

};

main.closePages = function() {

  isPageOpen = false;
  $body.removeClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  main.showMain();

};

main.togglePage = function(page) {

  isPageOpen = true;
  $body.addClass('overlay-page-is-open');

  $('.js-overlay-page.is-active').removeClass('is-active');
  $('.js-main.is-active').removeClass('is-active');
  $('.js-current-page').html($('nav ul li a[rel="'+page+'"]').text());

  setTimeout(function(){
    $('.js-overlay-page[data-page="'+page+'"]').toggleClass('is-active');
    $('nav ul li a[rel="'+page+'"]').parent().toggleClass('is-active');
  },440);

};

main.pauseAllVideos = function() {

  $('.js-slider__cell').find('iframe.playing').each(function() {
     command = {
       "method": "pause",
       "value": "true"
     };
     if (this != undefined) {
        //post our command to the iframe.
        this.contentWindow.postMessage(JSON.stringify(command), "*");
    }
  });

};

main.initVideo = function() {

  /* Grabbing objects to play with */
  var playButtons = $('.play'),
      pauseButtons = $('.pause');

  /* Helper function to send a message to Vimeo-iframe */
  function vimeoPost(action, value, target) {
  	var data = { method: action };
  	if (value) {
  		data.value = value;
  	}
  	target.contentWindow.postMessage(JSON.stringify(data), '*');
  }

  /* When clicking the playButton, send a "play"-message to given iframe*/
  playButtons.click(function() {
    var iframe = document.getElementById(this.dataset.id);
    vimeoPost('play',null,iframe);
    vimeoPost('setVolume',0.5,iframe);
    // iframe.setVolume(1);
  });

  /* When clicking the pauseButton, send a "pause"-message to given iframe */
  pauseButtons.click(function() {
     var iframe = document.getElementById(this.dataset.id);
     vimeoPost('pause',null,iframe);
  });

  /* When a message is received, deal with it */
  window.addEventListener('message', function(event) {
    /* Get message and sender from the event */
    var message = JSON.parse(event.data);
    var sender = document.getElementById(message.player_id);
    console.log(message);
    /* If the message.event is "ready", then send a message to add eventlisteners for play and pause also */
    if(message.event == "ready") {
   	 vimeoPost('addEventListener','play',sender);
     vimeoPost('addEventListener','pause',sender);
    }

    /* Because of the above, we will start receiving events when video is played or paused - we will toggle a class, just to show it visually */
    if(message.event == 'play') {
      console.log('playing');
      sender.classList.add('playing');
  		sender.parentNode.classList.add('is-playing');
    }
    if(message.event == 'pause') {
      sender.classList.remove('playing');
      sender.parentNode.classList.remove('is-playing');
    }

  }, false);

};


main.initSlider = function(slider) {

  if ($(slider).length > 0) {

    $(slider).removeClass('is-hidden');
		$(slider)[0].offsetHeight; // jshint ignore:line

    var cellAlign;
    if($(slider).hasClass('js-slider--product')) {
      cellAlign = 'left';
    } else {
      cellAlign = 'center';
    }

    var wrapAround;
    if($(slider).hasClass('js-slider--product')) {
      wrapAround = false;
    } else {
      wrapAround = true;
    }

		var $slider = $(slider).flickity({
			cellSelector: '.js-slider__cell',
			cellAlign: cellAlign,
			watchCSS: true,
			wrapAround: wrapAround,
			imagesLoaded: true,
			lazyLoad: 2,
			prevNextButtons: false,
			pageDots: false
		});

    var $caption = $('.js-slider__caption');
    var flkty = $(slider).data('flickity');

    $slider.on( 'select.flickity', function(event, index) {
      // set image caption using data-caption of slider cell
      $caption.text($(flkty.selectedElement).data('caption'));
      //Pause all playing videos after slide change;
      main.pauseAllVideos();
    });

    $slider.on( 'staticClick.flickity', function( event, pointer, cellElement, cellIndex ) {
      if ( cellElement == flkty.selectedElement ) {
        if($(cellElement).hasClass('has-zoom-state')) {
          main.openZoomView();
        }
      }
    });

    $('.js-slider__btn--previous').on( 'click', function() {
      $(slider).flickity('previous');
    });

    // next
    $('.js-slider__btn--next').on( 'click', function() {
      $(slider).flickity('next');
    });

  }



};

/*
main.initSlider = function() {

  $('.royalSlider').css('overflow', 'visible');

  $(".royalSlider").royalSlider({
    loop: true,
    keyboardNavEnabled: true,
    autoScaleSlider: false,
    autoHeight: false,
    imageScalePadding: 0,
    imageScaleMode: 'none',
    imageAlignCenter: false,
    slidesSpacing: 0,
    globalCaption: true,
    globalCaptionInside: false,
    navigateByClick: true,
    arrowsAutoHide: true,
    arrows: true,
    sliderDrag: true,
    sliderTouch: false,
    numImagesToPreload: 2,
    fadeinLoadedSlide: true,
    visibleNearby: {
      enabled: true,
      centerArea: 0.7,
      center: true,
      breakpoint: 768,
      breakpointCenterArea: 0.8,
      navigateByCenterClick: false
    },
    deeplinking: {
    	enabled: true,
      change: true,
    	prefix: ''
    }
  });

  $.fn.lazysizesRoyal = function(){
      var addPrevNextPreload = function(){
          var setPreload;
          var slider = $(this).data('royalSlider');

          if(!slider){return;}
          setPreload = function(){
              slider.currSlide.holder
                  .next()
                  .add(slider.currSlide.holder.prev())
                  .find('.lazyload')
                  .addClass('lazypreload')
              ;
          };
          setPreload();
          slider.ev.on('rsAfterSlideChange', setPreload);
      };
      return this.each(addPrevNextPreload);
  };

  $('.royalSlider').lazysizesRoyal();
  slider = $(".royalSlider").data('royalSlider');

  $('.rsGCaption').prependTo('.c-header__content');
  var videoIsPlaying = false;

  slider.ev.on('rsAfterSlideChange', function() {
    if (slider.currSlide.content.children('.o-slide--video').length > 0) {
      //If the slide has a video, trigger to play it
      slider.currSlide.content.children('.o-slide--video').find('video').trigger('play');
      videoIsPlaying = true;
      $btnToggleSound.addClass('is-visible');
   } else {
     if(videoIsPlaying == true) {
      $('video').trigger('pause');
     }
     $btnToggleSound.removeClass('is-visible');
   }

   images = slider.slides;

  });

};
*/

var my_mute = false;

main.toggleSound = function() {

  function toggleMute(elem) {
      elem.muted = !elem.muted;
      if(elem.muted == false) {
        $btnToggleSound.html('Sound on');
      }else {
        $btnToggleSound.html('Sound off');
      }
  }

  var videos = document.querySelectorAll("video"),
      audios = document.querySelectorAll("audio");

  [].forEach.call(videos, function(video) { toggleMute(video); });
  [].forEach.call(audios, function(audio) { toggleMute(audio); });

  my_mute = !my_mute;

};

main.setLayout = function() {

  ww = $(window).width();
  wh = $(window).height();
  dw = $(document).width();
  dh = $(document).height();

  if( ww < brmedsmall) {
    if ( $('.js-slider').length ) {
      $('.js-slider').css({'padding-top':$('.c-header__banner').outerHeight()});
  	}
  } else {
    if ( $('.js-slider').length ) {
      $('.js-slider').css({'padding-top':'0'});
  	}
  }

  main.setTitlePositions();

};

$(main.init);
